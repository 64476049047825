import React from "react"
import { connect } from "react-redux"
import Franja from "./franja"
import Meta from './meta'
import axios from "axios"
import { siteMetadata } from '../../gatsby-config'
import Medio from "./medio"
import Destacado from "./Destacado"


class Categoria extends React.Component {
  state = {
    franjas: [],
    categoria: null
  }

  componentDidMount() {
    this.props.ShowNav();
    this.loadCategoria();

  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.loadCategoria();
    }
  }

  loadCategoria = () => {
    const currentId = location.pathname.replace(/\/play\/categoria\/?/gi, "")
    if (currentId) {
      this.fetchCategoria(currentId);
    }
  }

  fetchCategoria = (id) => {
    const baseurl = siteMetadata.apiBaseURL;
    let url = baseurl + "/App_HttpHandlers/MobileAppHandler.ashx?accion=search&TipoDeBusqueda=CATEGORIA&IdCategoria=" + id
    axios
      .get(url)
      .then(({ data }) => {
        if (data) {
          this.setState({
            categoria: data,
            franjas: (data.Hijos && data.Hijos.length > 0) ? data.Hijos : []
          });
        }
      })
      .catch(error => {
        console.log(error);
      })
  }


  render() {
    const { franjas, categoria } = this.state;
    return (
      <div>
        <Meta title={categoria ? categoria.NombreCategoria : ""}
          site={siteMetadata} />


        <Destacado medio={categoria ? categoria.Destacado : null}
          Text={categoria ? categoria.NombreCategoria : null}
          Url={categoria ? categoria.UrlExterno : null}
          ImagenAsociadaCategoria={categoria ? categoria.ImagenAsociada : null}
        />

        {/*
        <div className="destacadoPreview">
          <div className="NombreCategoria">{categoria ? categoria.NombreCategoria : ""}</div>
        </div>
         */}


        {franjas && franjas.length > 0 ?
          <>
            {franjas.map((franja, i) => (
              <Franja {...this.props} franja={franja} key={franja.IdCategoria} />
            ))}
          </>
          :
          <>
            {categoria && categoria.Medios ?
              <div className="mediosResult row">
                <div className="col">
                  {categoria.Medios.map((medio, i) => {
                    return (
                      <div key={i} className="medioResult">
                        <Medio medio={medio} />
                      </div>
                    )
                  })
                  }
                </div>
              </div>
              : <div className="SinResultados">
                <h1> No se encontraron medios asociados a esta categoría </h1>
              </div>
            }
          </>
        }

      </div>
    )
  }

}




const mapStateToProps = () => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ShowNav: () => {
      dispatch({ type: 'SHOWNAV' })
    }
  }
}

const ConnectedCategoria = connect(
  mapStateToProps,
  mapDispatchToProps
)(Categoria)


export default ConnectedCategoria;


