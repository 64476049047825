import React from 'react';
import PropTypes from 'prop-types';
import { siteMetadata } from '../../gatsby-config'

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,

  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';



const Share = ({ socialConfig, tags }) => (
	<div className="post-social">
    <FacebookShareButton
      url={siteMetadata.siteUrl + socialConfig.config.url}
      quote={socialConfig.config.title}
      className="">
      <FacebookIcon
        size={32}
        round />
    </FacebookShareButton>

    <TwitterShareButton
      url={siteMetadata.siteUrl + socialConfig.config.url}
      title={socialConfig.config.title}
      via={socialConfig.twitterHandle.split('@').join('')}
      hashtags={tags}
      className="">
      <TwitterIcon
        size={32}
        round />
    </TwitterShareButton>

    <WhatsappShareButton
      url={siteMetadata.siteUrl + socialConfig.config.url}
      title={socialConfig.config.title}
      separator=":: "
      className="">
      <WhatsappIcon size={32} round />
    </WhatsappShareButton>
	</div>
);

Share.propTypes = {
	socialConfig: PropTypes.shape({
		twitterHandle: PropTypes.string.isRequired,
		config: PropTypes.shape({
			url: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
		}),
	}).isRequired,
	tags: PropTypes.arrayOf(PropTypes.string),
};
Share.defaultProps = {
	tags: [],
};

export default Share;

