import React from "react"
import Duration from './Duration'
import Moment from 'react-moment';

import { connect } from 'react-redux'
import { Arbol } from "../utils/arbol"
import { Link } from "gatsby"


class Atributo extends React.Component {

    medioTieneCategoria(idCategoria, medio) {
        return medio.CategoriasPredefinidas ? medio.CategoriasPredefinidas.includes(idCategoria) : false;
    }

    getValue(atributo, medio) {
        let result = null;
        try {

            let arbol = new Arbol();
            arbol.cargarArbol(this.props.arbolCategorias.raiz);


            switch (atributo.type) {
                case "categoria":
                    const coinciden = []
                    if (this.props.arbolCategorias.raiz) {
                        let rama = arbol.buscarRama(arbol.raiz, atributo.idCategoria)
                        if (rama.hijos !== undefined) {
                            Object.keys(rama.hijos).map((key) => {
                                if (this.medioTieneCategoria(rama.hijos[key].IdCategoria, medio)) {
                                    coinciden.push(rama.hijos[key])
                                }
                            })


                            result = (
                                <>
                                    {coinciden.length > 0 ?
                                        coinciden.map((categoria, i) => {
                                            return (

                                                <Link className="AtributoValor" key={i} to={"/play/categoria/" + categoria.IdCategoria}>
                                                    <em className="fa fa-link"></em> {categoria.NombreCategoria}
                                                </Link>

                                            )
                                        })
                                        : null
                                    }
                                </>
                            )

                        } else {

                            result = this.medioTieneCategoria(atributo.idCategoria, medio) ? "Si" : "No"
                        }

                    }
                    break;
                case "AtributosPredefinidos":
                    const currentAtributo = medio.AtributosPredefinidos[atributo.field];
                    let value = null;

                    if (currentAtributo) {
                        //console.log("currentAtributo", currentAtributo);
                        switch (currentAtributo.Tipo.toLowerCase()) {
                            case "url":
                                let url = currentAtributo.Value;
                                value = (
                                    <a target="_blank"
                                        href={url.match(new RegExp("https?://", "i"))
                                            ? url : `http://${url}`}
                                    ><em className="fa fa-link"></em> {url}</a>
                                )
                                //console.log("url", url)
                                break;
                            default:
                                value = currentAtributo.Value;

                        }
                    }

                    result = value
                    break;
                case "duracion":
                    result = <Duration seconds={medio[atributo.field]} />
                    break;
                case "date":
                    result = <Moment format="DD/MM/YYYY">{medio[atributo.field]}</Moment>
                    break;
                case "boolean":
                    result = medio[atributo.field] ? "Si" : "No";
                    break;
                case "url":
                    let url = medio[atributo.field]
                    result = medio[atributo.field] ?
                        <a target="_blank"
                            href={url.match(new RegExp("http://", "i"))
                                ? url : `http://${url}`}
                        ><em className="fa fa-link"></em> {url}</a>
                        : null;
                    break
                case "texto":
                default:
                    result = medio[atributo.field] ? medio[atributo.field] : null;
            }
        } catch (ex) {
            console.log(ex);
        }
        return result;
    }

    render() {
        let value = this.getValue(
            this.props.atributo,
            this.props.medio
        )
        if (!value) {
            return null
        }
        else if (typeof value == "object"
            && value.type !== undefined
            && value.props.children !== undefined
            && value.props.children == null) {
            return null
        }

        return (
            <li>
                <span className="Label">{this.props.atributo.label}</span>
                <span className="AtributoValor">{value}</span>
            </li>
        )
    }
}


const mapStateToProps = ({ arbolCategorias }) => {
    return {
        arbolCategorias
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

const ConnectedAtributo = connect(
    mapStateToProps,
    mapDispatchToProps
)(Atributo)

export default ConnectedAtributo
