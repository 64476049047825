import React from "react"
import Routes from "../routes"
import { isMobile } from 'react-device-detect';
import Meta from 'components/meta'
import { siteMetadata } from '../../gatsby-config'


class Play extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {


    return (
      <>
        <Meta site={siteMetadata} />
        <Routes {...this.props} isMobile={isMobile} />
      </>
    );
  }
}

export default Play
