import React from "react"
import { connect } from "react-redux"
import Meta from './meta'
import Medio from "./medio"
import Destacado from "./Destacado"
import { siteMetadata } from '../../gatsby-config'


class Resultados extends React.Component {
  state = {
  }

  componentDidMount() {
    this.props.ShowNav();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.loadBusqueda();
    }
  }

  loadBusqueda = () => {
    const query = location.pathname.replace(/\/play\/resultados\/?/gi, "")
    //console.log("get busqueda from query", query);
    if (query) {
    }
  }

  render() {
    //console.log("PROPS Resultados", this.props)
    const { resultadoBusqueda } = this.props.BusquedaTextoState;

    //console.log("resultadoBusqueda", resultadoBusqueda)
    return (
      <>
        <Meta title="Resultados" site={siteMetadata} />

        <Destacado medio={resultadoBusqueda ? resultadoBusqueda.Destacado : null}
          Text={`Resultados: "${this.props.BusquedaTextoState.query}"`} />



        {resultadoBusqueda.Medios && resultadoBusqueda.Medios.length > 0 ?
          <div className="mediosResult row">
            <div className="col">
              {resultadoBusqueda.Medios.map((medio, i) => {
                return (
                  <div key={i} className="medioResult">
                    <Medio medio={medio} />
                  </div>
                )
              })
              }
            </div>
          </div>
          : <div className="SinResultados">
            <h1> No se encontraron medios </h1>
          </div>
        }


      </>
    )
  }

}




const mapStateToProps = ({ busquedaTexto }) => {
  return {
    BusquedaTextoState: busquedaTexto
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ShowNav: () => {
      dispatch({ type: 'SHOWNAV' })
    },
    HideModalBusquedaTexto: () => {
      dispatch({ type: 'HIDEMODALBUSQUEDATEXTO' })
    },
  }
}

const ConnectedResultados = connect(
  mapStateToProps,
  mapDispatchToProps
)(Resultados)


export default ConnectedResultados;


