import React from "react"
import { connect } from "react-redux"
import Comentar from "./Comentar"
import Comentario from "./Comentario"
import { COMENTARIOPREFIX } from "../../utils/constantes"

class Comentarios extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    comentarios: []
  }

  componentDidMount() {
    this.actualizarComentarios(this.props.IdMedio)
  }


  actualizarComentarios(id) {
    this.props.ActualizarComentarios(id)
  }

  render() {
    const { comentarios } = this.props.comentariosState;
    const comentariosProcesados = {}

    if (comentarios) {
      comentarios.forEach((comentario) => {
        comentario.respuestas = []
        if (comentario.IdComentarioRespondido == 0) {
          comentariosProcesados[COMENTARIOPREFIX + comentario.IdComentario] = comentario;
        } else {
          let comentarioRespondido = comentariosProcesados[COMENTARIOPREFIX + comentario.IdComentarioRespondido];
          if (comentarioRespondido) {
            if (!Array.isArray(comentarioRespondido.respuestas)) {
              comentarioRespondido.respuestas = []
            }
            comentarioRespondido.respuestas.push(comentario);
          }
        }
      })
    }

    return (
      <>
        <Comentar IdMedio={this.props.IdMedio} />
        {
          Object.keys(comentariosProcesados).map((key, i) => {
            const comentario = comentariosProcesados[key]
            //console.log(comentario)
            return (
              <div className="ComentarioContainer" key={i}>
                <Comentario comentario={comentario} />
                <div className="Respuestas">
                  {typeof (comentario.respuestas) != "undefined" ?
                    comentario.respuestas.map((respuesta, j) => {
                      return (
                        <Comentario key={j} comentario={respuesta} esRespuesta={true} />
                      )
                    })
                    :
                    null}
                </div>
              </div>
            )
          })
        }

      </>
    )
  }
}



const mapStateToProps = ({ comentarios }) => {
  return {
    comentariosState: comentarios
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ActualizarComentarios: (idMedio) => {
      dispatch({ type: `REQUESTCOMENTARIOS`, idMedio })
    }
  }
}

const ConnectedComentarios = connect(
  mapStateToProps,
  mapDispatchToProps
)(Comentarios)



export default ConnectedComentarios
