import React from "react"
import { connect } from "react-redux"


class Comentar extends React.Component {
  constructor(props) {
    super(props)

    // Este enlace es necesario para hacer que `this` funcione en el callback
    this.handleComentar = this.handleComentar.bind(this);
    this.handleNombreChange = this.handleNombreChange.bind(this);
    this.handleNuevoComentarioChange = this.handleNuevoComentarioChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  state = {
    hideNombre: false,
    IdComentarioResponder: null,
    nuevoComentario: "",
    nombre: ""
  }

  componentDidMount() {
    if (typeof (this.props.Nombre) != "undefined") {
      this.setState({
        nombre: this.props.Nombre,
        hideNombre: true
      })
    }
    if (typeof (this.props.IdComentarioResponder) != "undefined") {
      this.setState({
        IdComentarioResponder: this.props.IdComentarioResponder,
      })
    }
  }



  handleComentar(e) {
    let valid = (this.state.hideNombre || this.state.nombre) && this.state.comentarioNuevo;
    if (valid) {
      this.props.PostComentario(this.props.IdMedio,
        this.state.comentarioNuevo,
        this.state.nombre,
        this.state.IdComentarioResponder,
        this
      );
    } else {
      let message = {}
      message.title = "Error..."
      if (this.state.hideNombre) {
        message.text = "No ingresó nada en la respuesta del comentario"
      } else {
        message.text = "Tanto el nombre como el comentario son obligatorios"
      }

      this.props.Alert(
        message.title,
        message.text
      );

    }


  }

  handleNuevoComentarioChange(event) {
    this.setState({
      comentarioNuevo: event.target.value
    });
  }

  handleNombreChange(e) {
    this.setState({
      nombre: event.target.value
    });
  }

  handleCancel(e) {
    if (this.props.refComentario !== undefined) {
      this.props.refComentario.setState({
        responder: false
      });
    }
  }

  callbackComentar(refComentar) {
    try {
      if (this.state.IdComentarioResponder) {
        refComentar.props.Alert("Respuesta", "Su comentario de respuesta fue registrado exitósamente")
        refComentar.props.ActualizarComentarios(refComentar.props.IdMedio);
      } else {
        refComentar.props.Alert("Comentar", "Los comentarios serán revisados antes de su publicación definitiva")
      }
      refComentar.setState({
        comentarioNuevo: "",
      });
    } catch (ex) {

    }
  }

  render() {
    return (
      <>


        <h2>
          {this.state.IdComentarioResponder ?
            "Respuesta"
            :
            "Comentar"
          }
        </h2>

        <div className="comentariosInputContainer">
          {!this.state.hideNombre ?
            <div className="row py-2">
              <div className="col-sm-3 text-right"><label htmlFor="ComentarioNombre">Nombre</label></div>
              <div className="col-sm-9">
                <input type="text" id="ComentarioNombre" value={this.state.nombre} onChange={this.handleNombreChange} />
              </div>
            </div>
            : null
          }
          <div className="row py-2">
            <div className="col-sm-3 text-right"><label htmlFor="ComentarioText">Comentario</label></div>
            <div className="col-sm-9">
              <textarea maxLength="250" id="ComentarioText" value={this.state.comentarioNuevo} onChange={this.handleNuevoComentarioChange} />
            </div>
          </div>
          <div className="py-2 clearfix">
            <button onClick={this.handleComentar}>
              {this.state.IdComentarioResponder ?
                "Responder"
                :
                "Comentar"}
            </button>
            {this.state.IdComentarioResponder ?
              <button onClick={this.handleCancel}>Cancelar</button>
              : null
            }
          </div>
        </div>
      </>
    )
  }
}


const mapStateToProps = ({ comentarios }) => {
  return {
    comentariosState: comentarios
  }
}

const mapDispatchToProps = dispatch => {
  return {
    Alert: (title, text) => {
      dispatch({
        type: `SHOWUSERMESSAGE`, title, text
      })
    },

    ActualizarComentarios: (idMedio) => {
      dispatch({ type: `REQUESTCOMENTARIOS`, idMedio })
    },

    PostComentario: (idMedio, comentarioNuevo, nombre, idComentarioResponder, refComentar) => {
      dispatch({
        type: `POSTCOMENTARIO`,
        idMedio,
        comentarioNuevo,
        nombre,
        idComentarioResponder,
        refComentar
      })
    }
  }
}

const ConnectedComentar = connect(
  mapStateToProps,
  mapDispatchToProps
)(Comentar)

export default ConnectedComentar
