import React from "react"
import { Router } from "@reach/router"

import Player from "components/Player"
import PrivateRoute from "components/PrivateRoute"
import Categoria from "components/Categoria"
import Resultados from "components/Resultados"
import Home from "components/Home"

class Routes extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Router>
          <Player {...this.props}  path="/play/player/*"   />
          <Categoria {...this.props}  path="/play/categoria/*"   />
          <Resultados  {...this.props} path="/play/resultados/*" />
        </Router>
      </>
    );
  }
}

export default Routes;
